import * as Sentry from "@sentry/vue"

export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup(nuxtApp) {
        const vueApp = nuxtApp.vueApp

        const config = useRuntimeConfig()
        if (!config.public.sentryDsn) return

        const router = useRouter()

        Sentry.init({
            app: vueApp,
            dsn: config.public.sentryDsn,
            environment: config.public.sentryEnvironment,
            integrations: [
              Sentry.browserTracingIntegration({ router }),
              Sentry.replayIntegration(),
            ],

            tracesSampleRate: 1,
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: 1,
        })

    }
})