<script setup lang="ts">

useHead({
  htmlAttrs: {
    class: 'scroll-smooth'
  }
})

</script>

<template>
  <div>
    <NuxtLoadingIndicator />

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <UModals />
  </div>
</template>